@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 17px;
}
  
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.swiper-button-next, .swiper-button-prev {
  width: 24px;
  height: 24px;
  border: 1px solid #3A7CC5;
  border-radius: 50%;
  top: calc(100% - 3px);
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-weight: bold;
  font-size: 14px;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0;
  z-index: 0;
}

.leaflet-container {
  background: transparent;
}

.leaflet-touch .leaflet-bar {
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 0 !important;
  gap: 0.5rem;
}

.leaflet-touch .leaflet-bar a {
  font-size: 1.5rem;
  color: #3A7CC5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  width: 32px;
  height: 32px;
  line-height: 27px;
}

.leaflet-bar a.leaflet-disabled {
  color: #ADADAD;
}

.leaflet-bar a:not(.leaflet-disabled):hover {
  color: #3A7CC5;
}

.leaflet-touch .leaflet-bar a:first-child,.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 50% !important;
  font-weight: normal;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-top {
  top: 50%;
}

.scrollable::-webkit-scrollbar {
  width: 0.35rem;
}

.scrollable::-webkit-scrollbar-track {
  border-radius: 5.875rem;
  background: #C9C9C9;
  margin: .25rem;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #47A386;
  border-radius: 5.875rem;
  border: 0.1rem solid #C9C9C9;
}

.scrollableRegions::-webkit-scrollbar-track {
  background: #C9C9C9;
}

.scrollableRegions::-webkit-scrollbar-thumb {
  background-color: #3A7CC5;
  border: 0.1rem solid #C9C9C9;
}

.scrollableReasons::-webkit-scrollbar-track {
  background: #C9C9C9;
}

.scrollableReasons::-webkit-scrollbar-thumb {
  background-color: #E8AE45;
  border: 0.1rem solid #C9C9C9;
}